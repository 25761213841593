
import React from 'react';
import ReactDOM from 'react-dom';
import FaucetHubHeader, { IFaucetHubHeaderProps } from './components/header/FaucetHubHeader';

(() => {
    if ((window as any).powfaucetHub) return;
    let powfaucetHub: any = (window as any).powfaucetHub = {};

    let sideloadScript = document.getElementById("powfaucethub-sideload");
    if(!sideloadScript) return;
    let hubBaseUrl: string = sideloadScript.dataset.base;

    let faucetWrappers = document.getElementsByClassName("faucet-wrapper");
    if(faucetWrappers.length == 0) return;
    let faucetWrapper: HTMLDivElement = faucetWrappers[0] as any;

    injectStyle(hubBaseUrl + "/dist/powfaucethub-sideload.css");

    let headerProps: IFaucetHubHeaderProps = {
    };
    let headerComp = React.createElement<IFaucetHubHeaderProps>(FaucetHubHeader, headerProps, []);
    let headerWrapper = document.createElement("DIV");
    headerWrapper.className = "faucethub-header-container";
    ReactDOM.render(headerComp, headerWrapper);
    faucetWrapper.parentElement.insertBefore(headerWrapper, faucetWrapper);
    powfaucetHub.header = headerComp;
})()

function injectStyle(url: string) {
    var head  = document.getElementsByTagName('head')[0];
    var link  = document.createElement('link');
    link.rel  = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    link.media = 'all';
    head.appendChild(link);
}