import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import './FaucetHubHeader.scss';

export interface IFaucetHubHeaderProps {
}

export interface IFaucetHubHeaderState {
}

export class FaucetHubHeader extends React.PureComponent<IFaucetHubHeaderProps, IFaucetHubHeaderState> {

  constructor(props: IFaucetHubHeaderProps, state: IFaucetHubHeaderState) {
    super(props);

    this.state = {
		};
  }

	public render(): React.ReactElement<IFaucetHubHeaderProps> {
    return (
      <div className='faucethub-header faucet-bootstrap'>
        <Navbar collapseOnSelect expand="lg" fixed="top" >
          <Container>
            <Navbar.Brand href="https://faucets.pk910.de">PoWFaucet</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown title={
                  <span>
                    Hole&scaron;ky
                  </span>
                 } id="holesky-faucet" autoClose>
                  <NavDropdown.Item href="https://holesky-faucet.pk910.de">PoW Faucet</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="https://github.com/eth-clients/holesky" target="_blank">
                    Testnet Details
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://faucetlink.to/holesky" target="_blank">
                    Other Faucets
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title={
                  <span>
                    Sepolia
                  </span>
                 } id="sepolia-faucet" autoClose>
                  <NavDropdown.Item href="https://sepolia-faucet.pk910.de">PoW Faucet</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="https://github.com/eth-clients/sepolia" target="_blank">
                    Testnet Details
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://faucetlink.to/sepolia" target="_blank">
                    Other Faucets
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title={
                  <span>
                    Ephemery
                  </span>
                 } id="ephemery-faucet" autoClose>
                  <NavDropdown.Item href="https://ephemery-faucet.pk910.de">PoW Faucet</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="https://github.com/ephemery-testnet/ephemery-resources" target="_blank">
                    Testnet Details
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
	}

}

/*
  <Nav>
    <Nav.Link href="https://faucets.pk910.de/#/stats">Stats</Nav.Link>
  </Nav>

  <NavDropdown.Divider />
  <NavDropdown.Item href="#action/3.4">
    Separated link
  </NavDropdown.Item>
*/

export default (props) => {
  return (
    <FaucetHubHeader 
      {...props}
    />
  );
};
